import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

//styles:

const SliderBlockListContainer = styled.div`
    padding: 0 10px;
    cursor: grab;
    user-select: none;
    height: 100%;
`


const SliderBlockListItem = styled.div`
    display: flex;
    flex-direction: column;  
    padding: 40px 35px;
    height: 100%;
    box-sizing: border-box;

    font-family: ${fonts.poppins};
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    color: ${colors.tertiary};
    background: #FFFFFF;
    box-shadow: 0 8px 10px 0 rgba(0,0,0,0.10);
    border: 1px solid #EFEFEF;
    border-radius: 5px;
`

const PostTitle = styled.h4`
    font-size: 23px;
    font-weight: bold;
    line-height: 26px;

    margin: 0 0 40px;
`

const TextContainer = styled.div`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    margin: 0 0 20px;

    * {
        margin: 0;
    }
`

const LinkToPost = styled(Link)`
    display: flex;
    color: ${colors.primary};
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-decoration: none;
    width: fit-content;
    margin-top: auto;

    :hover {
      color: ${colors.primaryDark};
    }
    :active {
      color: ${colors.primaryLight};
    }
`


const SliderPost = ({content, slug, path}) => {
    const excerptLimited = `${content.excerpt.slice(0, 140)} [...]`;

    return(
        <SliderBlockListContainer>
            <SliderBlockListItem>
                    <PostTitle dangerouslySetInnerHTML={{ __html: content.title }}/>
                    <TextContainer dangerouslySetInnerHTML={{ __html: excerptLimited }}/>
                    <LinkToPost to={path + slug}>Leer más</LinkToPost>
            </SliderBlockListItem>
        </SliderBlockListContainer>
    ) 
}

export default SliderPost;