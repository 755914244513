import React, {useRef} from "react"
import styled from "styled-components"
import Slider from "react-slick";
import {deviceSize} from "../../styles/device"

import blueArrow from "../../images/blue-arrow.svg"
import blueArrowDark from "../../images/blue-arrow-dark.svg"

import SliderPost from "./sliderPost"

const SliderBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .slick-slider {
        margin: 0 0 40px;
        height: 100%;
        overflow: visible;

        button {
            display: none !important;
        }
    }

    .slick-list {
        overflow: visible;
        
    }
    
    .slick-track {
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: inherit;

        .slick-slide {
            display: flex;
            align-items: stretch;
            height: inherit;
        }
    }
  
`

const NavButtons = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    text-align: center;

    padding: 0 10px;

    button {
        text-indent: -9999em;
        text-transform: uppercase;
        user-select: none;
        cursor: pointer;
        border: red;
        width: 40px;
        height: 40px;
        background: url('${blueArrow}') no-repeat center center;
        transition: .2s;
        
        :hover {
          background: url('${blueArrowDark}') no-repeat center center;
        }

        :active, :focus {
            outline: none;
            color: unset;
        }
    }

    .button_left {
        transform: rotate(90deg);
        margin-right: 20px;
    }

    .button_right {
        transform: rotate(-90deg);
    }

    .desactive {
        background: url('${blueArrow}') no-repeat center center;
        opacity: .5;
    }
`

const SliderOfPosts = ({sliderGallery, path, sliderFamily, currentPostId}) => {


    const SliderRef = useRef(null)

    const next = () => {
        SliderRef.current.slickNext();
      }
    const previous = () => {
        SliderRef.current.slickPrev();
      }
    
    const handleChangeSlide = (i) => {
        i === 0 ? document.querySelector('.button_left').classList.add('desactive') : document.querySelector('.button_left').classList.remove('desactive')
        window.innerWidth >= deviceSize.tablet ? (
            i === sliderGallery.length - 1 - SliderRef.current.props.slidesToShow ? document.querySelector('.button_right').classList.add('desactive') : document.querySelector('.button_right').classList.remove('desactive')
          ) : (
            i === sliderGallery.length - 1 - SliderRef.current.props.responsive[0].settings.slidesToShow ? document.querySelector('.button_right').classList.add('desactive') : document.querySelector('.button_right').classList.remove('desactive')
          )
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        afterChange: (current) => handleChangeSlide(current),
        responsive: [
            {
              breakpoint: deviceSize.tablet,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 300,
              }
            }
        ]
      };

    return (
        <SliderBlock>
            <Slider ref={SliderRef} {...settings}>
   
                {sliderGallery.map((post, index) => ( 
                    post.node.id !== currentPostId && <SliderPost
                        key={'SliderPost' + index}
                        content={post.node}
                        slug={post.node.slug}
                        path={path}
                    />
                ))}
            </Slider>
            
           <NavButtons  sliderFamily={sliderFamily}>
                <button className="desactive button_left" onClick={previous} />
                <button className="button_right" onClick={next} />
            </NavButtons>

        </SliderBlock>
    )
}
 
export default SliderOfPosts;