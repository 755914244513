import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import moment from "moment"
import "moment/locale/es"
import {Helmet} from "react-helmet"
import device from "../styles/device"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import Layout from "../layout"
import LayoutPages from './layoutPages'
import SliderOfPosts from "../components/sliderOfPosts"
import CtaForPosts from "../components/contactCta/ctaForPosts"
import { useSpring, animated } from 'react-spring'
import defaultRRSSImage from '../images/binaria_generica_rrss.jpg'

//dataLang:
import langDataEs from '../data/languages/es'
import langDataCa from '../data/languages/ca'
import langDataEn from '../data/languages/en'


const PostPageContainer = styled.section`
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
  max-width: 1024px;
  
  margin: auto;
  padding: 100px 8.3333333%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  margin: 100px auto 0;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const PostPageRow = styled.div`    
    margin: 0 -10px;
`

const PostTitle = styled.h1`
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  margin: 0;

  padding: 0 10px;

  @media ${device.phone} {
    font-size: 40px;
    line-height: 45px;
  }
`

const PostDate = styled.p`
  margin: 24px 0 60px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.tertiaryGrey};

  padding: 0 10px;
`

const MorePostsContainer = styled.div`
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
  max-width: 1440px;

  margin: auto;
  padding: 0 8.3333333% 100px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  @media ${device.tablet}{
    padding: 0 30px 140px;
  }

  @media ${device.phone}{
      padding: 0 20px 100px;
  }
`

const MorePostsRow = styled.div`
  margin: 0 -10px;
`

const MorePostsTitle = styled.h3`
  font-size: 20px;
  line-height: 30px;
  font-family: ${fonts.poppins};
  color: ${colors.primary};
  padding: 0 10px;
`

const PostTemplate = ( {data, pageContext} ) => {
  const post = data.wordpressPost;

  const spanishDate = moment(post.date).format("LL");

  const {sideMenu, cookies, footer, contactBanner: { postBanner}, post: {otherPostsTitle} } = (pageContext.lang === "es" && langDataEs) || (pageContext.lang === "ca" && langDataCa) || (pageContext.lang === "en" && langDataEn)
  

  const titleProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(-200px)`
    },
    to: {
      opacity: 1,
      transform: `translateY(0px)`
    }
  })

  const contentProps = useSpring({
    from: {
      opacity: 0, 
      transform: `translateY(200px)`
    },
    to: {
      opacity: 1,
      transform: `translateY(0px)`
    }
  })

  const rrssImage = post.featured_media ? post.featured_media.localFile.publicURL : defaultRRSSImage
  const featuredImage = `${data.site.siteMetadata.siteUrl}${rrssImage}`

  return (
    <Layout sideMenu={sideMenu} cookies={cookies} footer={footer}>
      <Helmet>
        <title>{post.yoast_meta.yoast_wpseo_title}</title>
        <meta name="description" content={post.yoast_meta.yoast_wpseo_metadesc} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://binaria.com/blog/${post.slug}`} />
        <meta property="og:title" content={post.yoast_meta.yoast_wpseo_title} />
        <meta property="og:description" content={post.yoast_meta.yoast_wpseo_metadesc} />
        <meta property="og:image" content={featuredImage} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://binaria.com/blog/${post.slug}`} />
        <meta property="twitter:title" content={post.yoast_meta.yoast_wpseo_title} />
        <meta property="twitter:description" content={post.yoast_meta.yoast_wpseo_metadesc} />
        <meta property="twitter:image" content={featuredImage}></meta>
      </Helmet>

      <PostPageContainer>
        <PostPageRow>

          <animated.div style={titleProps}>
            <PostTitle dangerouslySetInnerHTML={{ __html: post.title }} />
            <PostDate dangerouslySetInnerHTML={{ __html: spanishDate }} />
          </animated.div>

          <animated.div style={contentProps}>
            <LayoutPages content={post.content} />
            <CtaForPosts postBanner={postBanner}/>
          </animated.div>

      </PostPageRow>
    </PostPageContainer>

    <MorePostsContainer>
      <MorePostsRow>
      
        <MorePostsTitle>{otherPostsTitle}</MorePostsTitle>  
        <SliderOfPosts path={sideMenu.primary[4].slug} sliderGallery={pageContext.allPosts.edges} sliderFamily={"design"} currentPostId={post.id}/>

      </MorePostsRow>
    </MorePostsContainer>

    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      date
      slug
      id
      featured_media{
        localFile{
          publicURL
        }
      }
      yoast_meta{
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
