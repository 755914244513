
import React from "react"
import styled from "styled-components"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import device from "../styles/device"



const LayoutPage = styled.div`
  padding: 0 10px;
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    a > img {
      margin-top: 25px;
    }
  }

  b, h2, h2 > a, h3, h3 > a {
    color: ${colors.tertiary};
  }

  h1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 45px;
    margin-top: 60px;

    @media ${device.phone} {
    font-size: 40px;
    line-height: 45px;
    }
  }

  h2 {
    font-size: 33px;
    font-weight: bold;
    line-height: 37px;
    margin-top: 80px;
    display: flex;
    align-items: center;
  
    
    .gatsby-image-wrapper, img {
      width: 40px !important;
      height: auto !important;
    }
    .gatsby-image-wrapper {
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: bold;
    line-height: 32px;
    margin-top: 50px;
  }

  h4 {
    font-size: 23px;
    font-weight: bold;
    line-height: 26px;
    margin-top: 50px;
  }

  .has-large-font-size {
    font-size: 20px;
    line-height: 30px;
    margin: -5px 0 40px;
  }

  li {
    font-weight: 300;
    margin-bottom: 1em;
  }


  a {
      overflow-wrap: break-word;
    }

  p img, figure, 
  figure img {
    width: 100% !important;
    height: auto;
    margin: 0;
    padding: 0;
  }

  figcaption {
    color: ${colors.tertiaryGrey};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    margin: 20px 0 30px;
  }

  .btn{
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
    padding: 10px 60px;
    margin: 20px auto 0 0;
    font-family: ${fonts.poppins};
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    transition: all .4s;
    text-decoration: none;

    &:hover {
      background-color: ${colors.primaryDark};
      cursor: pointer;
    }
  }

  .cta{
    margin-bottom: 40px;
    margin-top: 40px;
    padding:40px;
    width: 100%;
    background: linear-gradient(-225deg, rgb(34, 20, 254) 0%, rgb(252, 76, 93) 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    .title{
      color: white;
      font-size: 40px;
      font-family: ${fonts.poppins};
      font-weight: bold;
      line-height: 45px;
      margin-bottom: 0;
      margin-top: 0;
      margin-right: auto;
    }

    .cta-btn{
      background: white;
      padding: 10px 40px;
      color: ${colors.tertiary};
      font-size: 16px;
      font-family: ${fonts.poppins};
      font-weight: bold;
      text-align: center;
      line-height: 29px;
      text-decoration: none;
      transition: all .4s;
  
      &:hover {
        opacity: 0.8;
      }
    }
  }
`

export default ({content}) => (
  <LayoutPage dangerouslySetInnerHTML={{__html: content}} />
)