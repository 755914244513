import React from "react"
import styled from 'styled-components'
import Link from "gatsby-link"

import fonts from "../../styles/fonts"
import colors from "../../styles/colors"

import device from '../../styles/device'


const CtaForPostsContainer = styled.div`
    padding: 0 10px;
`

const CtaForPostsRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 80px auto 0;
    padding: 40px;

    background: linear-gradient(-225deg, rgb(34, 20, 254) 0%, rgb(252, 76, 93) 100%);


    @media ${device.tablet}{
    margin: 60px auto 0;
    }

    @media ${device.phone}{
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 40px auto 0;
    }
`

const CtaForPostsTitle = styled.h2`
    color: white;
    font-size: 40px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 45px;
    margin: 0 auto 0 0;

    @media ${device.phone}{
        margin-bottom: 40px;
    }
`

const CtaForPostsButton = styled(Link)`
    background: white;
    padding: 10px 40px;
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    text-align: center;
    line-height: 29px;
    text-decoration: none;
    transition: all .4s;

  &:hover {
    opacity: 0.8;
  }
`

const CtaForPosts = ({postBanner}) => (
    <CtaForPostsContainer>
        <CtaForPostsRow>

            <CtaForPostsTitle>{postBanner.title}</CtaForPostsTitle>
            <CtaForPostsButton to={postBanner.link}>{postBanner.button}</CtaForPostsButton>
        
        </CtaForPostsRow>
    </CtaForPostsContainer>
);

export default CtaForPosts;